import './App.css';
import Navbar from './components/nav-bar/NavBar'
import logo from "./images/logo.png"
import profileImage from "./images/profile1.jpg"
import { ContextApi } from './store/context/ContextApi'
import Footer from './components/footer/Footer'
import { Suspense, lazy, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogOut from './global/logout'
import { useDispatch, useSelector } from 'react-redux'
import { getGlobalSettingsFunc } from './store/slicers/settings/GetGlobalSettingsSlicer'
import { getWishlistFunc } from './store/slicers/wishlist/GetWishlistSlicer'
import { getCartFunc } from './store/slicers/cart/GetCartSlicer'
import { getLanguageSettingsFunc } from './store/slicers/settings/language/GetLanguageSettingsSlicer'
import i18n from "i18next";
import OverlayLoader from './components/overlay-loader/OverlayLoader';
import { useSettings } from './hooks/useSettings';

const VerifyNewsLetterEmail = lazy(() => import ('./components/forms/news-letters/VerifynewsLetterEmail'))
const ErrorPage = lazy(() => import ('./global/error/ErrorPage'))
const LoginPage = lazy(() => import ('./pages/auth/login-page/Loginage'))
const RegisterPage = lazy(() => import ('./pages/auth/regitster-page/RegisterPage'))
const ProductsPage = lazy(() => import ('./pages/products/ProductsPage'))
const Profile = lazy(() => import ('./pages/profile/Profile'))
const VerifyRegisterationEmail = lazy(() => import ('./components/forms/auth/VerifyEmail'))
const ContactUsPage = lazy(() => import ('./pages/contact-us/ContactUsPage'))
const WishlistPage = lazy(() => import ('./pages/wishlist/WishListPage'))
const HomePage = lazy(() => import ('./pages/home/HomePage'))
const ChangePassForm = lazy(() => import ('./components/forms/profile/ChangePassForm'))
const NewAdressPage = lazy(() => import ('./components/forms/adresses/NewAdressPage'))
const UpdateAddressPage = lazy(() => import ('./components/forms/adresses/UpdateAddressPage'))
const CartPage = lazy(() => import ('./pages/cart/CartPage'))
const ProductDetails = lazy(() => import ('./pages/products/ProductDetails'))
const CheckoutPage = lazy(() => import ('./pages/checkout/ChekoutPage'))
const OrderDetails = lazy(() => import ('./components/orders/OrderDetails'))
const ResetPassword = lazy(() => import ('./components/forms/auth/ResetPassword'))
const OrderCallback = lazy(() => import ('./pages/order-callback-page/OrderCallback'))

function App() {
  const expire_date = window.localStorage.getItem("expire_date");
  const user = JSON.parse(window.localStorage.getItem("user"));
	// const {data:settingsData} = useSelector(
  //   state => state.getGlobalSettingsSlicer
  // )
  const {data:settingsData} = useSettings();
  const {loading:getLanguagesLoading , data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)

    const routes = [
      {
        path : "/",
        component : <HomePage/>
      },
      {
        path : "/register",
        component : <RegisterPage/>
      },
      {
        path : "/verify-register-email",
        component : <VerifyRegisterationEmail/>
      },
      {
        path : "/login",
        component : <LoginPage/>
      },
      {
        path : "/profile",
        component : <Profile/>
      },
      {
        path : "/change-pass",
        component : <ChangePassForm/>
      },
      {
        path : "/reset-password",
        component : <ResetPassword/>
      },
      {
        path : "/new-address",
        component : <NewAdressPage/>
      },
      {
        path : "/update-address/:addressId",
        component : <UpdateAddressPage/>
      },
      // {
      //   path : "/about",
      //   component : <AboutPage/>
      // },
      {
        path : "/contact-us",
        component : <ContactUsPage/>
      },
      {
        path : "/verify-mail",
        component : <VerifyNewsLetterEmail/>
      },
      {
        path : "/products",
        component : <ProductsPage/>
      },
      {
        path : "/product-details/:productId",
        component : <ProductDetails/>
      },
      {
        path : "/wishlist",
        component : <WishlistPage/>
      },
      {
        path : "/cart",
        component : <CartPage/>
      },
      {
        path : "/checkout",
        component : <CheckoutPage/>
      },
      {
        path : "/order-details/:orderId",
        component : <OrderDetails/>
      },
      {
        path : "/order/callback",
        component : <OrderCallback/>
      },
      {
        path : "*",
        component : <ErrorPage/>
      },
    ]
    const changeTitle = (newTitle) => {
      document.title = newTitle;
    };
    const changeFavicon = (iconURL) => {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = iconURL;
    };
    const {pathname} = useLocation()
    const [settings , setSettings] = useState({})
    const globalData = {
      logo : logo,
      profileImage : profileImage,
      // userpanelApi : "https://apis.hamzastore-eg.com",x  
      userpanelApi : process.env.REACT_APP_PUBLIC_API,
      siteInfo : settings
    }
    useEffect(() => {
      // Change the favicon to a new icon URL
      changeFavicon(globalData.siteInfo.site_logo);
      changeTitle(globalData.siteInfo.site_title)
      // You can also revert back to the original favicon on unmount if needed
      return () => {
          changeFavicon(globalData.siteInfo.site_logo);
          changeTitle(globalData.siteInfo.site_title);
      };
  }, [globalData]); 
    const settingsApi = `${globalData.userpanelApi}/api/global-settings`;
    const languageAPi = `${globalData.userpanelApi}/api/language-settings`;
    const dispatch = useDispatch();
    useEffect(() => {
        const getSettings = () => {
            // dispatch(getGlobalSettingsFunc(settingsApi))
            if(window.localStorage.getItem("langSlug")) {
              dispatch(getLanguageSettingsFunc(`${languageAPi}?lang=${window.localStorage.getItem("langSlug")}`))
            }else {
              dispatch(getLanguageSettingsFunc(languageAPi))
            }
        }
        getSettings()
    },[]);

    useEffect(() => {
      settingsData?.data && setSettings(settingsData?.data);
      !settingsData?.data && window.localStorage.getItem("frontPrimaryColor") && document.documentElement.style.setProperty("--primary" , window.localStorage.getItem("frontPrimaryColor"));
      !settingsData?.data && window.localStorage.getItem("frontSecondaryColor") && document.documentElement.style.setProperty("--secondary" , window.localStorage.getItem("frontSecondaryColor"));
      !settingsData?.data && window.localStorage.getItem("frontPrimaryHover") && document.documentElement.style.setProperty("--primary-hover" , window.localStorage.getItem("frontPrimaryHover"));
      !settingsData?.data && window.localStorage.getItem("frontTitleColor") && document.documentElement.style.setProperty("--title" , window.localStorage.getItem("frontSecondaryColor"));
      if(settingsData?.data) {
        document.documentElement.style.setProperty("--primary" , settingsData?.data?.front_primary_color);
        document.documentElement.style.setProperty("--secondary" , settingsData?.data?.front_secondary_color);
        document.documentElement.style.setProperty("--primary-hover" , settingsData?.data?.front_primary_color_hover);
        document.documentElement.style.setProperty("--title" , settingsData?.data?.front_secondary_color);
        !window.localStorage.getItem("frontPrimaryColor") && window.localStorage.setItem("frontPrimaryColor" , settingsData?.data?.front_primary_color);
        !window.localStorage.getItem("frontPrimaryHover") && window.localStorage.setItem("frontPrimaryHover" , settingsData?.data?.front_primary_color_hover);
        !window.localStorage.getItem("frontSecondaryColor") && window.localStorage.setItem("frontSecondaryColor" , settingsData?.data?.front_secondary_color);
        !window.localStorage.getItem("frontTitleColor") && window.localStorage.setItem("frontTitleColor" , settingsData?.data?.front_secondary_color);
      }
    },[settingsData])

    const wishlistApi = `${globalData.userpanelApi}/api/wishlists`;
    const cartApi = `${globalData.userpanelApi}/api/carts`;
      useEffect(() => {
          const getWishlits = () => {
              dispatch(getWishlistFunc(wishlistApi))
          }
          user && parseInt(user.verifiedStatus) === 1 && getWishlits()
      },[user])
      useEffect(() => {
          const getCart = () => {
              dispatch(getCartFunc(cartApi))
          }
          user && parseInt(user.verifiedStatus) === 1 && getCart()
      },[user])

      useEffect(() => {
        const fetchTranslations = async () => {
          if (languageData) {
            const { defaultLang, all_word } = languageData;
            if (defaultLang && all_word) {
              const { direction, slug } = defaultLang;
              document.documentElement.dir = direction; // Set the direction dynamically
              window.localStorage.setItem("langSlug" , defaultLang.slug);
              console.log(defaultLang.slug)
    
              i18n.addResources(slug, 'translation', all_word);
              i18n.changeLanguage(slug);
              if (defaultLang.slug && defaultLang.slug === "ar" && window.localStorage.getItem("langSlug") === 'ar') {
                const existingLink = document.querySelector('link[href="./assets/css/style-rtl.css"]');
                if (!existingLink) {
                  const rtlStyleLink = document.createElement('link');
                  rtlStyleLink.href = './assets/css/style-rtl.css';
                  rtlStyleLink.rel = 'stylesheet';
                  document.head.appendChild(rtlStyleLink);
                  }
              }else {
                const existingLink = document.querySelector('link[href="./assets/css/style-rtl.css"]');
                if(existingLink) {
                  existingLink.href = ""
                }
              }
            } else {
              console.error('Invalid language data structure', languageData);
            }
          }
        };
    
        fetchTranslations();
      }, [languageData]);

      return (
    <>
    {
      console.log(settingsData)
    }
    <ToastContainer style={{zIndex : 999999}}/>
    <ContextApi.Provider value={globalData}>
      {
        pathname !== "/verify-register-email"
        &&
        pathname !== "/verify-newsletter-email"
        &&
        pathname !== "/change-pass"
        &&
        pathname !== "/reset-password"
        &&
        pathname !== "/order/callback"
        &&
        languageData
        &&
		<Navbar/>
      }
      <Suspense fallback={<OverlayLoader/>}>
        <Routes>
          {
            routes.map((route , index) => <Route key={index} path={route.path} element={route.component} />)
          }
        </Routes>
      </Suspense>
      {
        pathname !== "/verify-register-email"
        &&
        pathname !== "/verify-newsletter-email"
        &&
        pathname !== "/change-pass"
        &&
        pathname !== "/reset-password"
        &&
        pathname !== "/order/callback"
        &&
        <>
        <Footer/>
        </>
      }
      {
        expire_date
        &&
        <LogOut/>
      }
    </ContextApi.Provider>
      {getLanguagesLoading && <OverlayLoader/>} 
    </>
  )
}

export default App
