import React from 'react'
import ReactDOM from 'react-dom/client'
import "./i18n.js"
import App from './App.js'
import './index.css';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Store } from './store/slicers/store.js'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const clientProvider = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={Store}>
        <QueryClientProvider client={clientProvider}>
          <App />
        </QueryClientProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
)
