import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const categoriesApi = `${process.env.REACT_APP_PUBLIC_API}/api/categories`;

export const useCategories = (langSlug) => {
    return useQuery({
        queryKey: ['getCategories', langSlug], // Query key
        queryFn: async () => {
            const response = await axios.get(`${categoriesApi}?lang=${langSlug}`);
            return response.data;
        },
        refetchOnWindowFocus : false,
        enabled: !!langSlug, // Ensures the query only runs if `langSlug` is defined
    });
};
